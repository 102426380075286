
import Vue from "vue";
import DatepickerFormatted from "../../../common/inputs/DatepickerFormatted.vue";

import showService from "@/services/admin/showService";
import affiliateService from "@/services/admin/affiliateService";

import { Messages } from "@/constants";
import { UserInfo } from "@/types/User";
import { PaginatedResponse } from "@/types/Pagination";
import { ShowInfo, ShowType, ShowAffiliateInfo, ShowPerson, JudgeScheduleInfo, PointSchedule } from '@/types/admin/Show';

export default Vue.extend({
    name: "ShowEntry",
    components: {
        DatepickerFormatted,
    },
    data: function () {
        return {
            tab: null,
            badShowData: false,
            showInfo: {
                showId: "",
                clubId: "",
                showType: {} as ShowType,
                showCoordinator: {} as ShowPerson,
                showSecretary: {} as ShowPerson,
                sponsoringAffiliate: {} as ShowAffiliateInfo,
                judgeSchedules: [] as JudgeScheduleInfo[],
            } as ShowInfo,
            pointSchedules: [] as PointSchedule[],
            pointScheduleIds: [] as any[],
            isEditMode: false,
            isValid:true,
            showValidationDialog: false,
            validationResult: {
                warnings: [],
                errors: []
            } as any,
            reloadPage: false,
            showTypes: [] as ShowType[],
            showTypeDescriptions: [] as string[],
            showTypeIds: [] as string[],
            searchAffiliateDialog: false,
            searchAffiliatesHeaders: [
                { text: "Affiliate ID", align: "left", value: "affiliateId" },
                { text: "Affiliate Name", align: "left", value: "affiliateName" },
                { text: "Home State", align: "left", value: "state" },
                { text: "Active", align: "left", value: "isActive" },
            ],
            searchPersonHeaders: [
                { text: "Judge ID", align: "center", value: "judgeId" },
                { text: "Member ID", align: "center", value: "memberId" },
                { text: "Name", align: "center", value: "name" },
                { text: "Active", align: "center", value: "isActiveMember" },
                { text: "", align: "center", value: "" },
            ],
            timeOptions: ["",
                "AM", "PM"
            ],
            isLoading: false,
            hasSearchAffiliates: false,
            searchAffiliateResults: {} as PaginatedResponse<object>,
            searchParamsAffiliate: {
                pageSize: 20,
                page: 1,
                ascending: false,
                affiliateId: "",
                affiliateName: "",
                state: "",
                affiliateRep: "",
                activeOnly:true,
            },
            searchParamsPerson: {
                pageSize: 20,
                page: 0,
                ascending: false,
                memberId: "",
                name: "",
                judgeId: "",
                activeMembersOnly: true,
                affiliateId:"",
            },
            applyPointSchedule:true,
            isErrorAffiliate: false,
            searchPersonDialog: false,
            searchPersonResults: Array<object>(),
            selectedShowPerson: -1,
            hasSearchPerson: false,
            allowShowCoordSearch: false,
            showCoordList: [] as any[],
            selectedShowCoordId: -1,
            selectedShowTypeId: "",
            //Judge Related
            searchJudgeHeaders: [
                { text: "Judge ID", align: "center", value: "judgeId" },
                { text: "Member ID", align: "center", value: "memberId" },
                { text: "Name", align: "center", value: "name" },
                { text: "Active", align: "center", value: "isActiveMember" },
                { text: "Quals", align: "center", value: "judgeQuals" },
                { text: "", align: "center", value: "" },

            ],
            judgeScheduleHeaders: [
                { text: "Judge ID", align: "left", value: "judgeId" },
                { text: "Name", align: "left", value: "name" },
                { text: "Start Date", align: "left", value: "startDate" },
                { text: "End Date", align: "left", value: "endDate" },
                { text: "Type ID", align: "left", value: "showTypeId" },
                { text: "Time", align: "left", value: "time" },
                { text: "Category", align: "left", value: "category" },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            selectedJudgeShowTypeId: "",
            searchJudgeResults: Array<object>(),
            searchParamsJudge: {
                pageSize: 20,
                page: 1,
                ascending: false,
                memberId: "",
                name: "",
                judgeId: "",
                activeMembersOnly: false,
                affiliateId: "",
                showTypeId: "",
            },
            editedIndex: -1,
            editedItem: {} as JudgeScheduleInfo,
            defaultItem: {} as JudgeScheduleInfo,
            dialog: false,
            dialogDelete: false,
            hasSearchJudge: false,
            judgeErrorMessage : "",
            errorMessage: '',
            infoMessage: '',
            showErrorMessage: false,
            showInfoMessage: false
        };
    },
    methods: {
        reloadData: function () {
            this.badShowData = false;
            this.errorMessage = '';
            this.showErrorMessage = false;
            
            if (this.showId) {
                showService
                    .getShowById(this.showId)
                    .then((res) => {
                        this.showInfo = res;
                        this.showCoordList = res.affiliateShowCoords;
                        this.selectedShowTypeId = this.showInfo.showTypeId;
                        this.selectedJudgeShowTypeId = this.showInfo.showTypeId;
                        this.editedItem.startDate = this.showInfo.startDate;
                        this.editedItem.endDate = this.showInfo.endDate;
                        this.editedItem.showTypeId = this.showInfo.showTypeId;
                        if (this.showInfo.showCoordinatorId == null || this.showInfo.showSecretaryId == null) {
                            this.badShowData = true;
                        }
                    }).catch(e => {
                        this.initializeNewShow();
                        this.isEditMode = true;
                    });
            }
            else {
                this.initializeNewShow();
                this.isEditMode = true;
                this.reloadPage = true;
                this.allowShowCoordSearch = false;
                this.judgeErrorMessage = "";
            }
        },
        initializeNewShow() {
            this.showInfo = {
                showId: "",
                clubId: "",
                showTypeId: "",
                showCity: "",
                showState: "",
                showCountry: "",
                location: "",
                international: "",
                newsletterNotes: "",
                notes: "",
                showType: {} as ShowType,
                showCoordinator: {
                    personId: -1,
                    memberId: "",
                    name: "",
                    firstName: "",
                    lastName: "",
                } as ShowPerson,
                showSecretary: {
                    personId: -1,
                    memberId: "",
                    name: "",
                    firstName: "",
                    lastName: "",
                } as ShowPerson,
                sponsoringAffiliate: {
                    affiliateId: "",
                    affiliateName: "",
                    homeState: "",
                } as ShowAffiliateInfo,
                judgeSchedules: [] as JudgeScheduleInfo[],
            } as ShowInfo;
        },
        isValidNumber(value: string) {
            if (!value) {
                return true;
            }
            if (!isNaN(parseFloat(value))) return true;
            return 'Must be valid number';
        },
        toggleEditMode: function () {
            if (this.isEditMode) {
                this.validateAndConfirmSavingChanges();
            }

            this.isEditMode = !this.isEditMode;
        },
        updateDates: function (val: any, key: string) {
            this.$data.showInfo[key] = val?.date;
            this.$data.showInfo = Object.assign({}, this.$data.showInfo);
        },
        updateText: function (key: string, event: string) {
            this.$data.showInfo[key] = event;
        },
        updateAndValidateDataEntry: function () {
        this.errorMessage = "";
        this.showErrorMessage = false;

            if (this.showInfo.startDate == null || this.showInfo.endDate == null) {
                this.errorMessage = "Start and End Dates are required."
            this.showErrorMessage = true;
                return false;
            }
            if (this.selectedShowTypeId == null || this.selectedShowTypeId == "") {
                this.errorMessage = "Show Type is required.";
                this.showErrorMessage = true;
                return false;
            }
            else {
                this.showInfo.showTypeId = this.selectedShowTypeId;
            }
            if (this.showInfo.clubId == null || this.showInfo.clubId == "") {
                this.errorMessage = "Sponsoring Affiliate is required.";
                this.showErrorMessage = true;
                return false;
            }
            if (this.showInfo.showSecretaryId == null || this.showInfo.showCoordinatorId == null) {
                this.errorMessage = "Show Coordinator and Secretary are required.";
                this.showErrorMessage = true;
                return false;
            }

            if (this.showInfo.showCity == null || this.showInfo.showCity.length < 2) {
                this.errorMessage = "City is required.";
                this.showErrorMessage = true;
                return false;
            } else if (this.showInfo.showCity.length > 50) {
                this.errorMessage = "City is too long.";
                this.showErrorMessage = true;
                return false;
            }

            if (this.showInfo.showState == null || this.showInfo.showState.length < 2) {
                this.errorMessage = "State is required.";
                this.showErrorMessage = true;
                return false;
            } else if (this.showInfo.showState.length > 2) {
                this.errorMessage = "State is too long.";
                this.showErrorMessage = true;
                return false;
            }

            if (this.showInfo.showCountry != null && this.showInfo.showCountry.length > 50) {
                this.errorMessage = "Country is too long.";
                this.showErrorMessage = true;
                return false;

            }
            if (this.showInfo.newsletterNotes != null && this.showInfo.newsletterNotes.length > 100) {
                this.errorMessage = "S & T Note is too long.";
                this.showErrorMessage = true;
                return false;
            }

            return true;
        },
        validateAndConfirmSavingChanges: function () {
            if (this.updateAndValidateDataEntry()) {
                showService
                    .validateShowEdit(this.showInfo)
                    .then((res: any) => {
                        this.validationResult = res;
                        if (this.validationResult.errors.length > 0 || this.validationResult.warnings.length > 0) {
                            this.showValidationDialog = true;
                        }
                        else {
                            this.saveChanges();
                            this.validationResult = {};
                        }
                    })
            }
            else {
                this.isEditMode = false; //will toggle it back to edit mode
            }
        },
        saveChanges: function () {
            this.errorMessage = "";
            this.showErrorMessage = false;

            this.showValidationDialog = false;
            this.$store.dispatch("changeLoaderStatus", true);
            showService
                .editShowDetails(this.showInfo)
                .then((res) => {
                    if (res.success) {
                        if (res.id && res.id != this.$props.showId) {
                            this.$router.push({ path: `/admin/shows/${res.id}` }).catch(() => { });
                        }
                        this.reloadData();
                        this.isEditMode = false;
                        this.infoMessage = Messages.SaveSuccessful;
                        this.showInfoMessage = true;
                    } else {         
                        if (res.messages != null) {     
                            this.errorMessage = Messages.SaveError + " " + res.messages.join(', ');
                            this.showErrorMessage = true;
                        }
                        else {
                            this.errorMessage = "Unable to process show create/edit at this time.";
                            this.showErrorMessage = true;
                        }
                    }
                })
                .catch((e) => {                  
                this.errorMessage = Messages.SaveError;
                this.showErrorMessage = true;
                })
                .finally(() => this.$store.dispatch("changeLoaderStatus", false));
            
        },
        //Affiliate
        toggleAffiliateSearch: function () {
            this.searchAffiliateDialog = !this.searchAffiliateDialog;
        },
        affiliateSelect: function (row: any) {
            this.searchAffiliateDialog = false;
            if (row.affiliateId) {
                affiliateService
                    .getShowAffiliateById(row.affiliateId)
                    .then((res) => {
                        this.showInfo.sponsoringAffiliate = res;
                        this.showInfo.clubId = row.affiliateId;
                        if (this.applyPointSchedule) {
                            this.updatePointSchedule();
                        }
                        this.updateShowCoordList(row.affiliateId);
                    }).catch(e => {
                        console.log(e);
                        this.errorMessage = "Unable to retrieve Affiliate Information.";
                        this.showErrorMessage = true;
                    });
            }
        },
        reloadAffiliate: function () {
            affiliateService
                .getShowAffiliateById(this.showInfo.clubId)
                .then((res) => {
                    this.showInfo.sponsoringAffiliate = res;
                    this.updateShowCoordList(this.showInfo.clubId);
                }).catch(e => {
                    console.log(e);
                    this.errorMessage = "Unable to retrieve Affiliate Information.";
                    this.showErrorMessage = true;
                });
        },
        clearSearchAffiliate: function () {
            this.searchAffiliateResults = {} as PaginatedResponse<object>;
        },
        updateShowCoord: function () {
            if (this.selectedShowCoordId == null) {
                return false;
            }
            this.showInfo.showCoordinatorId = this.selectedShowCoordId;
            this.showInfo.showCoordinator = this.showCoordList.find((x: ShowPerson) => x.personId == this.selectedShowCoordId);
            return true;
        },
        searchAffiliate: function (fromPagination?: boolean) {
            if (fromPagination !== true) this.searchParamsAffiliate.page = 1;
            this.isLoading = true;
            this.hasSearchAffiliates = false;
            affiliateService
                .getAffiliatesList({
                    page: 0,
                    itemsPerPage: 20,
                    sortBy: [],
                    sortDesc: [],
                    groupBy: [],
                    groupDesc: [],
                    multiSort: false,
                    mustSort: false,
                }, this.searchParamsAffiliate)
                .then((res) => {
                    this.searchAffiliateResults = res;
                    this.hasSearchAffiliates = true;
                    this.isLoading = false;
                })
                .catch((e) => {
                    this.isLoading = false;
                    console.log(e.response.data.errors);
                    this.errorMessage = "Unable to search for Affiliates";
                    this.showErrorMessage = true;
                    return;
                });
            this.isLoading = false;
        },
        updatePointSchedule: function () {
            if (this.showInfo.sponsoringAffiliate != null) {
                this.showInfo.dogSched = this.showInfo.sponsoringAffiliate.dogSched;
                this.showInfo.altBitchSched = this.showInfo.sponsoringAffiliate.altBitchSched;
                this.showInfo.altDogSched = this.showInfo.sponsoringAffiliate.altDogSched;
                this.showInfo.bitchSched = this.showInfo.sponsoringAffiliate.bitchSched;
            }
        },
        updateShowCoordList: function (affiliateId: string) {
            this.showCoordList = [] as any[];
            affiliateService.getAffiliateShowCoordinators(affiliateId).then((res: any) => {
                this.showCoordList = res;
                //if there is only one show coord to choose from and this is a new show, choose it.
                //They can still update the club, and do a refresh to pull more choices
                if (this.showCoordList.length == 1 && (!this.showId || !this.showInfo.showCoordinatorId)) {
                    this.showInfo.showCoordinatorId = this.showCoordList[0].personId;
                    this.showInfo.showCoordinator = {
                        personId: this.showCoordList[0].personId,
                        memberId: this.showCoordList[0].memberId,
                        active: this.showCoordList[0].active,
                        name: this.showCoordList[0].name,
                        firstName: this.showCoordList[0].firstName,
                        lastName: this.showCoordList[0].lastName,
                    };
                }
            });
        },
        isMemberOfAffiliate: function (memberId: String) {
            if (!this.showInfo || memberId == null) {
                return false;
            }
            if (this.showInfo.sponsoringAffiliate == null
                || this.showInfo.sponsoringAffiliate.members == null
                || this.showInfo.sponsoringAffiliate.members.length == 0) {
                return false;
            }
            var member = this.showInfo.sponsoringAffiliate.members.find((x: any) => x.memberId.trim() == memberId.trim());
            if (member != null) {
                return true;
            }
            return false;
        },
        //People Search
        searchPerson: function (fromPagination?: boolean) {
            this.searchPersonResults= Array<object>();
            if (fromPagination !== true) this.searchParamsPerson.page = 0;
            this.isLoading = true;
            this.hasSearchPerson = false;
            this.searchParamsPerson.affiliateId = this.showInfo.sponsoringAffiliate.affiliateId;
            showService
                .getShowPeopleList(this.searchParamsPerson)
                .then((res) => {
                    this.searchPersonResults = res;
                    this.hasSearchPerson = true;
                    this.isLoading = false;
                })
                .catch((e) => {
                    this.isLoading = false;
                    console.log(e.response.data.errors);
                    this.errorMessage = "Unable to search for Show Person";
                    this.showErrorMessage = true;
                    return;
                });
            this.isLoading = false;
        },
        togglePersonSearch: function (forPerson: number) {
            this.selectedShowPerson = forPerson;
            this.$data.searchPersonDialog = !this.$data.searchPersonDialog;
        },
        clearSearchPerson: function () {
            this.searchParamsPerson = {
                pageSize: 20,
                page: 1,
                ascending: false,
                memberId: "",
                name: "",
                judgeId: "",
                activeMembersOnly: true,
                affiliateId: "",
            };
            this.searchPersonResults = Array<object>();
        },
        personSelect: function (row: any) {
            this.searchPersonDialog = false;
            if (row.personId != null) {
                this.showInfo.showSecretaryId = row.personId;
                this.showInfo.showSecretary = {
                    personId: row.personId,
                    memberId: row.memberId,
                    active: row.active,
                    name: row.name,
                    firstName: row.firstName,
                    lastName: row.lastName,
                };
                if (row.name != null) {
                    this.showInfo.showSecretaryName = row.name;
                }
                else {
                    this.showInfo.showSecretaryName = row.firstName + ' ' + row.lastName;
                }
            }
        },
        //Judge Schedules
        reloadShowTypes: function () {
            showService.getJudgeShowTypes().then((res: any) => {
                this.showTypes = res;
                for (let i = 0; i < this.showTypes.length; i++) {
                    this.showTypeDescriptions.push(this.showTypes[i].description);
                    this.showTypeIds.push(this.showTypes[i].showTypeId);
                }
            });
        },
        searchJudge: function (fromPagination?: boolean) {
            this.searchJudgeResults = Array<object>();
            if (fromPagination !== true) this.searchParamsJudge.page = 1;
            this.isLoading = true;
            this.hasSearchJudge = false;
            
            showService
                .getShowJudgesList(this.searchParamsJudge)
                .then((res) => {
                    this.searchJudgeResults = res;
                    this.hasSearchJudge = true;
                    this.isLoading = false;
                })
                .catch((e) => {
                    this.isLoading = false;
                    console.log(e.response.data.errors);
                    this.errorMessage = "Unable to search for Judge";
                    this.showErrorMessage = true;
                    return;
                });
            this.isLoading = false;
        },
        judgeSelect: function (row: any) {
            this.judgeErrorMessage = "";
            if (row.judgeId != null) {
                this.editedItem.personId = row.personId;
                this.editedItem.judgeId = row.judgeId;
                this.editedItem.memberId = row.memberId;
                this.editedItem.firstName = row.firstName;
                this.editedItem.lastName = row.lastName;
                this.editedItem.name = row.name;
                this.editedItem.showId = this.showId;
            }

            this.clearJudgeSearch();
        },
        clearJudgeSearch: function () {
            this.searchJudgeResults = [] as any[];
            this.searchParamsJudge = {
                pageSize: 20,
                page: 1,
                ascending: false,
                memberId: "",
                name: "",
                judgeId: "",
                activeMembersOnly: false,
                affiliateId: "",
                showTypeId: "",
            };
        },
        updateJudgeScheduleDates: function (val: any, key: string) {
            this.$data.showInfo.judgingSchedules[key] = val?.date;
            this.$data.showInfo.judgingSchedules = Object.assign({}, this.$data.showInfo.judgingSchedules);
        },
        updateEditedItemDates: function (val: any, key: string) {
            this.$data.editedItem[key] = val?.date;
            this.$data.editedItem = Object.assign({}, this.$data.editedItem);
        },
        editItem(item: JudgeScheduleInfo) {
            this.editedIndex = this.showInfo.judgeSchedules.indexOf(item)
            this.editedItem = Object.assign({}, item)               
            this.dialog = true
        },
        initializeJudgeItem() {
            this.editedItem.startDate = this.showInfo.startDate;
            this.editedItem.endDate = this.showInfo.endDate;
            this.editedItem.showTypeId = this.showInfo.showTypeId;
            this.editedItem.showId = this.showId;
            this.dialog = true;

        },
        deleteItem(item: JudgeScheduleInfo) {
            this.editedIndex = this.showInfo.judgeSchedules.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },
        deleteItemConfirm() {
            this.showInfo.judgeSchedules.splice(this.editedIndex, 1)
            this.closeDelete()
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        save() {
            if (this.editedItem.judgeId == null || this.editedItem.judgeId.length == 0) {
                this.errorMessage = "You must select a Judge.";
                
                return;
            }
            if (this.editedItem.category != null && this.editedItem.category.length > 400) {
                this.errorMessage = "Category is too long.";
                return;
            }
            if (this.editedIndex > -1) {
                Object.assign(this.showInfo.judgeSchedules[this.editedIndex], this.editedItem)
            }
            else {
                if (this.showInfo) {
                    this.showInfo.judgeSchedules.push(this.editedItem)
                }
            }
            this.close();
        },
        
    },
    created: function () {
        
        showService.getPointSchedules().then((res: any) => {
            this.pointSchedules = res;
            this.pointScheduleIds.push("");
            for (let i = 0; i < this.pointSchedules.length; i++) {
                this.pointScheduleIds.push(this.pointSchedules[i].pointScheduleId);
            }
        });

        this.reloadShowTypes();
        this.reloadData();

        if (this.showId) {
            if (this.showInfo.affiliateShowCoords && this.showInfo.affiliateShowCoords.length > 0) {
                this.showCoordList = this.showInfo.affiliateShowCoords;
            }
        }
    },
    watch: {
        showId: function (newVal: String) {
            this.reloadData();
        },
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    props: {
        showId: { type: String },
    },
    computed: {
        userInfo(): UserInfo {
            return this.$store.state.userInfo;
        },
        formTitle() {
            return this.$data.editedIndex === -1 ? "Add Judge" : "Edit Judge";
        },
    },
});
