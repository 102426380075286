
import Vue from "vue";
import adminTitlingService from "@/services/admin/titlingService";
import ReportViewer from "@/components/Admin/Reports/ReportViewer.vue";
import { MetaNames } from "@/constants";

export default Vue.extend({
  components: { ReportViewer },
  name: "CertPrinting",
    data: function () {
        return {
            title: MetaNames.AdminCertificatePrinting,
            Report_Name: 'Asca.Api.Reports.rpt8110_Title_Certificate.rdlx',
            Report_URL: '`/Admin/reports/filteredcertificates/${certType}`',
            showReportDialog: false,
            certType: "DNA",
            showMarkPrintedDialog: false,
            snackbar: false,
            snackbarColor: "green",
            snackbarText: "",
        };
    },
  methods: {
    showTitleDialog: function (){   
      this.Report_Name = "Asca.Api.Reports.rpt8110_Title_Certificate.rdlx";
      this.Report_URL = "/Admin/reports/filteredcertificates/"+this.certType;
      this.showReportDialog = true;
    },
    showTitleDialogFull: function (){
      this.Report_Name = "Asca.Api.Reports.rpt8111_Title_Certificate_Full.rdlx";
      this.Report_URL = "/Admin/reports/filteredcertificates/"+this.certType;
      this.showReportDialog = true;
    },

    toggleMarkPrintedDialog() {
      this.showMarkPrintedDialog = !this.showMarkPrintedDialog;
    },
    markUnprintedAsPrinted() {
      this.toggleMarkPrintedDialog();
      adminTitlingService
        .markUnprintedCertsPrinted(this.certType)
        .then((res) => {
            if (res.success) {
                this.snackbar = true;
                this.snackbarText = "Successfully marked certificates as printed";
                this.snackbarColor = "green";
            } else {
                this.snackbar = true;
                this.snackbarText = "Error occured while trying to mark certificates as printed";
                this.snackbarColor = "red";   
            }
        })
        .catch((e) => {
            console.error(e);
            this.snackbar = true;
            this.snackbarText = "Error occured while trying to mark certificates as printed";
            this.snackbarColor = "red";   
        });
    },
  },
  watch: {
    showReportDialog(newVal: boolean) {
      if (!newVal) {
        this.showMarkPrintedDialog = true;
      }
    },
  },
});
